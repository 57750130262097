


export const URL='http://localhost:3000/'

// export const REST_API = 'http://127.0.0.1:5000/'

// export const REST_API = 'https://shopamerica-api.skyniche.website/'
// const REST_API = 'https://shopamerica.ng/admin/'
export const REST_API = 'https://api.shopamerica.ng/'


